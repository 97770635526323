<template>
    <header class="header--main" v-if="visibility">
        <div class="container">
            <a href="javascript:;">Skip</a>
        </div>
    </header>
</template>
<script>
    export default {
        name: "GodfatherHeader",
        data() {
            return {
                visibility: false
            }
        },
        mounted() {
            this.visibility = false
        }
    }
</script>
<style lang="scss" scoped>
    .header--main {
        background: #111317;
        box-shadow: 0px 8px 24.8px 0px rgba(0, 0, 0, 0.35);
        height: 60px;
        display: flex;
        position: relative;
        z-index: 999;
    }

    .header--main .container {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
    }

    .header--main a {
        font-weight: 600;
        font-size: 18px;
        color: #fff;
        text-decoration: none;
        font-family: "Red Hat Display", sans-serif;
    }

    @media(max-width: 1300px) {
        .header--main {
            height: 48px;
            padding: 0 24px;

            .container {
                width: 100%;
            }
        }
    }

    @media(max-width: 480px) {
        .container {
            padding: 0 10px;
        }
    }
</style>