<template>
    <div>
        <SpecialGfOffer :headerVisibility="false"/>
    </div>
</template>
<script>
import SpecialGfOffer from '../components/SpecialGfOffer.vue';


export default {
    name: "SpecialOffer",
    components: {SpecialGfOffer}
}
</script>
<style lang="scss" scoped>
    
</style>