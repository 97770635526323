<script>
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import {GODFATHER_FLOW} from "@/types/flows";
import { mapState } from "vuex";

export default {
    name: 'PreActionFlowBasedRedirect',
    mixins: [AuthenticatedMixin],
    render() {
        return null
    },
    computed: {
        ...mapState({
            payment_history: state => state.credits.payment_history
        })
    },
    async mounted() {

        if(this.$route.meta?.restrictFlowRedirect) {
            return this.$emit('success')
        }

        if([GODFATHER_FLOW].includes(this.auth.user.flow) && ! this.payment_history) {

            await this.$router.push({name: 'credits'})

        }

        this.$emit('success')

    }
}
</script>