<template>
    <main class="aside-main">
        <teleport to="#overlay">
            <transition name="fade" mode="out-in">
                <div class="overlay overlay--filter o-flex o-flex--center o-flex--justify-center" @click="asideTrigger = !asideTrigger" v-if="asideTrigger"></div>
            </transition>
        </teleport>

        <div class="o-container">
            <div class="sub-header">
                <Banner />
            </div>

            <div class="sub-header--content">

                <div class="filter-text-trigger">
                    <a class="filter-trigger" @click="asideTrigger = !asideTrigger">
                        <svg fill="none" stroke-width="1.5" width="24" viewBox="0 0 24 24">
                            <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" d="M4 3h16a1 1 0 0 1 1 1v1.586a1 1 0 0 1-.293.707l-6.415 6.414a1 1 0 0 0-.292.707v6.305a1 1 0 0 1-1.243.97l-2-.5a1 1 0 0 1-.757-.97v-5.805a1 1 0 0 0-.293-.707L3.292 6.293A1 1 0 0 1 3 5.586V4a1 1 0 0 1 1-1Z"/>
                        </svg>
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="26" height="22"><g data-name="Icon ionic-ios-options"><path data-name="Path 21" d="M15.706 18.499a2.5 2.5 0 0 1 4.587 0h4.706a1 1 0 0 1 1 1 1 1 0 0 1-1 1h-4.706a2.5 2.5 0 0 1-4.587 0H1a1 1 0 0 1-1-1 1 1 0 0 1 1-1Z"/><path data-name="Path 22" d="M5.706 9.999a2.5 2.5 0 0 1 4.588 0h14.705a1 1 0 0 1 1 1 1 1 0 0 1-1 1H10.293a2.5 2.5 0 0 1-4.588 0H1a1 1 0 0 1-1-1 1 1 0 0 1 1-1Z"/><path data-name="Path 23" d="M15.706 1.5a2.5 2.5 0 0 1 4.587 0h4.706a1 1 0 0 1 1 1 1 1 0 0 1-1 1h-4.706a2.5 2.5 0 0 1-4.587 0H1a1 1 0 0 1-1-1 1 1 0 0 1 1-1Z"/></g></svg> -->
                    </a>
                </div>
                <div class="sub-nav-selected-filters" :class="{'has-filter': searchCriteria.length}">
                    <ul class="mb0 tags">
                        <li @click="s.callback()" v-for="s in searchCriteria.slice(0,5)" :key="s" class="tags__list-item">
                            <a class="tags__item">
                                <span>{{s.title}}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="5.967" height="5.966"><path data-name="Icon ionic-ios-close" d="M3.69 2.983 5.821.852a.5.5 0 0 0-.706-.706L2.984 2.277.853.145a.5.5 0 1 0-.706.706l2.131 2.132L.146 5.114a.5.5 0 0 0 .706.706l2.132-2.131L5.115 5.82a.5.5 0 0 0 .706-.706Z" fill="#fff"/></svg>
                            </a>
                        </li>
                        <li class="tags__numb" v-if="searchCriteria.length > 5">
                            <span>
                                + {{searchCriteria.length - 5}}
                            </span>
                        </li>
                    </ul>
                    <div class="order-by-dropdown">
                        <Accordion :opened="order" @accordion = "accordionChange">
                            <template v-slot:asideTitle>
                                {{$t('search.order')}}
                                <div class="tags-aside">
                                    <div><span>{{$t(searchOptions.searchResultsOrderBy.find(e => e.value === searchPayload.orderBy).code)}}</span></div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="9.29" height="6"><path data-name="Icon awesome-angle-down" d="M4.154 5.795.201 1.845a.694.694 0 0 1 0-.985l.66-.655a.694.694 0 0 1 .985 0l2.8 2.8 2.8-2.8a.694.694 0 0 1 .985 0l.656.656a.694.694 0 0 1 0 .985l-3.95 3.95a.691.691 0 0 1-.982 0Z"/></svg>
                                </div>
                            </template>
                            <template v-slot:asideOptions>
                                <ul class="mb0 recently-list">
                                    <li v-for="i in searchOptions.searchResultsOrderBy" :key="`o_${i.code}`">
                                        <a @click="order = false; searchPayload.orderBy = i.value; requestSearch(); pushDataLayer(SEARCH_ORDER_BY_FILTER, auth.user)" :class="this.searchPayload.orderBy === i.value ?'is-active':''">{{$t(i.code)}}</a>
                                    </li>
                                </ul>
                            </template>
                        </Accordion>
                    </div>

                </div>
            </div>

            <article class="results-content" :class="asideTrigger?'is-active':''">

                <ContentLoader class="loader" v-if="!loaded" />


                <div class="o-flex o-flex--start">
                    <aside class="aside" :class="asideTrigger?'is-active':''">
                        <div class="aside-scroll">
                            <div class="aside-header">
                                <h4>{{$t('search.filter_results')}}</h4>
                                <a @click="reset" :class="searchCriteria.length?'is-active':''" class="clear">{{$t('search.reset_all')}}</a>
                            </div>
                            <SwitchButton class="pr mt mb" :checked="!searchPayload.show_contacted" @switch="value => {searchPayload.show_contacted = ! value; pushDataLayer(SEARCH_HIDE_CONTACTED_FILTER, auth.user)}">
                                <h4 class="mb0 u-font-400">{{$t('search.hide_contacts')}}</h4>
                            </SwitchButton>
                            <div class="aside__filters">

                                <!-- <div class="distance">
                                    <div class="o-flex o-flex--center o-flex--justify mb-">
                                        <p class="mb0">{{$t('form_fields.distance_label')}}</p>
                                        <div class="tags-aside">
                                            <div v-if="defaultDistance !== searchPayload.distance"><span>{{searchPayload.distance}}</span> km</div>
                                        </div>
                                    </div>
                                    <div class="pl-- pr--">
                                        {{searchPayload.distance}} km
                                        <div class="aside-option__wrapper mt- pl- pr-">
                                            <VueSlider :min="5" :max="200" :interval="5" v-model="searchPayload.distance" @change="pushDataLayer(SEARCH_DISTANCE_FILTER, auth.user)" />
                                        </div>
                                    </div>

                                </div>     -->


                                <Accordion :opened="true">
                                    <template v-slot:asideTitle>
                                        {{$t('form_fields.distance_label')}}
                                        <div class="tags-aside">

                                            <div v-if="defaultDistance !== searchPayload.distance"><span>{{searchPayload.distance}}</span> km</div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="9.29" height="6"><path data-name="Icon awesome-angle-down" d="M4.154 5.795.201 1.845a.694.694 0 0 1 0-.985l.66-.655a.694.694 0 0 1 .985 0l2.8 2.8 2.8-2.8a.694.694 0 0 1 .985 0l.656.656a.694.694 0 0 1 0 .985l-3.95 3.95a.691.691 0 0 1-.982 0Z"/></svg>
                                        </div>
                                    </template>
                                    <template v-slot:asideOptions>
                                        {{searchPayload.distance}} km
                                        <div class="aside-option__wrapper mt- pl-- pr--">
                                            <VueSlider :min="5" :max="200" :interval="5" v-model="searchPayload.distance" @change="pushDataLayer(SEARCH_DISTANCE_FILTER, auth.user)" />
                                        </div>
                                        <SwitchButton class="pt+ " style="margin-left: -10px; margin-right: -10px;" :checked="searchPayload.country_strict" @switch="value => {searchPayload.country_strict = !! value; pushDataLayer(SEARCH_COUNTRY_STRICT_FILTER, auth.user)}">
                                            <h4 class="mb0 u-font-400">{{$t('search.country_strict', {country: stateSearchLocation?.country_long})}}</h4>
                                        </SwitchButton>
                                    </template>
                                </Accordion>
                                <SwitchButton class="pt pb" style="border-bottom: 1px solid #efefef;" :checked="searchPayload.verified" @switch="value => {searchPayload.verified = !! value; pushDataLayer(SEARCH_VERIFIED_ONLY_FILTER, auth.user)}">
                                    <h4 class="mb0 u-font-400">{{$t('search.verified')}}</h4>
                                </SwitchButton>
                                <Accordion :opened="true">
                                    <template v-slot:asideTitle>
                                        {{$t('form_fields.age_label')}}
                                        <div class="tags-aside">
                                            <div v-if="defaultAge[0] !== searchPayload.age[0] || defaultAge[1] !== searchPayload.age[1]">
                                                    <span>
                                                        {{`${searchPayload.age[0]} - ${searchPayload.age[1]}`}}
                                                    </span>
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="9.29" height="6"><path data-name="Icon awesome-angle-down" d="M4.154 5.795.201 1.845a.694.694 0 0 1 0-.985l.66-.655a.694.694 0 0 1 .985 0l2.8 2.8 2.8-2.8a.694.694 0 0 1 .985 0l.656.656a.694.694 0 0 1 0 .985l-3.95 3.95a.691.691 0 0 1-.982 0Z"/></svg>
                                        </div>
                                    </template>
                                    <template v-slot:asideOptions>
                                        {{`${searchPayload.age[0]} - ${searchPayload.age[1]}`}}
                                        <div class="aside-option__wrapper mt- pl-- pr--">
                                            <VueSlider :min="defaultAge[0]" :max="defaultAge[1]" v-model="searchPayload.age" @change="pushDataLayer(SEARCH_AGE_FILTER, auth.user)" :interval="1" />
                                        </div>
                                    </template>
                                </Accordion>
                                <Accordion  :opened="true" v-if="auth.user.gender === TYPE_GENDER_MALE">
                                    <template v-slot:asideTitle>
                                        {{$t('users.looking_for')}}
                                        <div class="tags-aside">
                                            <div v-if="firstSelectedInterest">
                                                <span>{{$t(`signup.new_registration.interests.${firstSelectedInterest.code}${firstSelectedInterest.code === 'selling_pics' ? (auth.user.gender === TYPE_GENDER_MALE ? '.sb' : '.sd') : ''}`)}}</span>
                                            </div>
                                            <div v-if="searchPayload.interested_in.length > 1"> {{searchPayload.interested_in.filter(e => e !== searchPayload.interested_in[0]).length > 0 ?  ('+' + searchPayload.interested_in.filter(e => e !== searchPayload.interested_in[0]).length) : ''}}</div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="9.29" height="6"><path data-name="Icon awesome-angle-down" d="M4.154 5.795.201 1.845a.694.694 0 0 1 0-.985l.66-.655a.694.694 0 0 1 .985 0l2.8 2.8 2.8-2.8a.694.694 0 0 1 .985 0l.656.656a.694.694 0 0 1 0 .985l-3.95 3.95a.691.691 0 0 1-.982 0Z"/></svg>
                                        </div>
                                    </template>
                                    <template v-slot:asideOptions>
                                        <div v-for="i in searchOptions.interests" :key="i.code" class="checkbox">
                                            <input class="form-check-input" v-model="searchPayload.interested_in" :value="i.value" type="checkbox" :id="`interests_${i.value}`" @change="pushDataLayer(SEARCH_LOOKING_FOR_FILTER, auth.user)">
                                            <label class="checkbox__label" :for="`interests_${i.value}`">
                                                <div class="checkbox__icon">
                                                    <svg width="16" viewBox="0 0 24 24" xml:space="preserve"><path fill="#fff" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                                                </div>
                                                <template v-if="i.code === 'selling_pics'">
                                                    {{ucFirst($t(`signup.new_registration.interests.selling_pics.${auth.user.gender === TYPE_GENDER_MALE ? 'sb' : 'sd'}`))}}
                                                </template>
                                                <template v-else>
                                                    {{ucFirst($t(`signup.new_registration.interests.${i.code}`))}}
                                                </template>
                                            </label>
                                        </div>
                                    </template>
                                </Accordion>
                                <div class="order-by-filter">
                                    <Accordion>
                                        <template v-slot:asideTitle>
                                            {{$t('search.order')}}
                                            <div class="tags-aside">
                                                <div><span>{{$t(searchOptions.searchResultsOrderBy.find(e => e.value === searchPayload.orderBy).code)}}</span></div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.29" height="6"><path data-name="Icon awesome-angle-down" d="M4.154 5.795.201 1.845a.694.694 0 0 1 0-.985l.66-.655a.694.694 0 0 1 .985 0l2.8 2.8 2.8-2.8a.694.694 0 0 1 .985 0l.656.656a.694.694 0 0 1 0 .985l-3.95 3.95a.691.691 0 0 1-.982 0Z"/></svg>
                                            </div>
                                        </template>
                                        <template v-slot:asideOptions>
                                            <ul class="mb0 recently-list">
                                                <li v-for="i in searchOptions.searchResultsOrderBy" :key="`o_${i.code}`">
                                                    <a @click="searchPayload.orderBy = i.value; requestSearch(); pushDataLayer(SEARCH_ORDER_BY_FILTER, auth.user)" :class="this.searchPayload.orderBy === i.value ?'is-active':''">{{$t(i.code)}}</a>
                                                </li>
                                            </ul>
                                        </template>
                                    </Accordion>
                                </div>
                                <Accordion>
                                    <template v-slot:asideTitle>
                                        {{$t('form_fields.body_type_label')}}
                                        <div class="tags-aside">
                                            <div v-if="searchPayload.body_type.length">
                                                <span>{{$t(`form_fields.body_type_options_${searchOptions.bodyTypes.find(e => searchPayload.body_type.includes(e.value))?.value}`)}}</span>
                                            </div>
                                            <div v-if="searchPayload.body_type.length > 1">
                                                {{searchPayload.body_type.filter(e => e !== searchPayload.body_type[0]).length > 0 ?  ('+' + searchPayload.body_type.filter(e => e !== searchPayload.body_type[0]).length) : ''}}
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="9.29" height="6"><path data-name="Icon awesome-angle-down" d="M4.154 5.795.201 1.845a.694.694 0 0 1 0-.985l.66-.655a.694.694 0 0 1 .985 0l2.8 2.8 2.8-2.8a.694.694 0 0 1 .985 0l.656.656a.694.694 0 0 1 0 .985l-3.95 3.95a.691.691 0 0 1-.982 0Z"/></svg>
                                        </div>
                                    </template>
                                    <template v-slot:asideOptions>
                                        <div v-for="b in searchOptions.bodyTypes" :key="b" class="checkbox">
                                            <input class="form-check-input" v-model="searchPayload.body_type" @change="pushDataLayer(SEARCH_BODY_TYPE_FILTER, auth.user)" :value="b.value" type="checkbox" :id="`body_${b.value}`">
                                            <label class="checkbox__label" :for="`body_${b.value}`">
                                                <div class="checkbox__icon">
                                                    <svg width="16" viewBox="0 0 24 24" xml:space="preserve"><path fill="#fff" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                                                </div>
                                                {{$t(`form_fields.body_type_options_${b.value}`)}}
                                            </label>
                                        </div>
                                    </template>
                                </Accordion>
                                <Accordion>
                                    <template v-slot:asideTitle>
                                        {{$t('form_fields.height_label')}}
                                        <div class="tags-aside">
                                            <div v-if="defaultHeight[0] !== searchPayload.height[0] || defaultHeight[1] !== searchPayload.height[1]"><span>{{`${searchPayload.height[0]} - ${searchPayload.height[1]}`}}</span>cm</div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="9.29" height="6"><path data-name="Icon awesome-angle-down" d="M4.154 5.795.201 1.845a.694.694 0 0 1 0-.985l.66-.655a.694.694 0 0 1 .985 0l2.8 2.8 2.8-2.8a.694.694 0 0 1 .985 0l.656.656a.694.694 0 0 1 0 .985l-3.95 3.95a.691.691 0 0 1-.982 0Z"/></svg>
                                        </div>
                                    </template>
                                    <template v-slot:asideOptions>
                                        {{`${searchPayload.height[0]} - ${searchPayload.height[1]}`}}cm
                                        <div class="aside-option__wrapper mt- pl-- pr--">
                                            <VueSlider :min="130" :max="230" v-model="searchPayload.height"  @change="pushDataLayer(SEARCH_HEIGHT_FILTER, auth.user)" :interval="1" />
                                        </div>
                                    </template>
                                </Accordion>
                                <div>
                                    <Accordion>
                                        <template v-slot:asideTitle>
                                            {{$t('form_fields.drinking_label')}}
                                            <div class="tags-aside">
                                                <div v-if="searchPayload.drinking.length">
                                                    <span>
                                                        {{$t(`form_fields.drinking_options_${searchOptions.drinking.find(e => searchPayload.drinking.includes(e.value))?.value}`)}}
                                                    </span>
                                                </div>
                                                <div v-if="searchPayload.drinking.length > 1"> {{searchPayload.drinking.filter(e => e !== searchPayload.drinking[0]).length > 0 ?  ('+' + searchPayload.drinking.filter(e => e !== searchPayload.drinking[0]).length) : ''}}</div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.29" height="6"><path data-name="Icon awesome-angle-down" d="M4.154 5.795.201 1.845a.694.694 0 0 1 0-.985l.66-.655a.694.694 0 0 1 .985 0l2.8 2.8 2.8-2.8a.694.694 0 0 1 .985 0l.656.656a.694.694 0 0 1 0 .985l-3.95 3.95a.691.691 0 0 1-.982 0Z"/></svg>
                                            </div>
                                        </template>
                                        <template v-slot:asideOptions>
                                            <div v-for="d in searchOptions.drinking" :key="d.value" class="checkbox">
                                                <input class="form-check-input" v-model="searchPayload.drinking" :value="d.value" @change="pushDataLayer(SEARCH_DRINKING_FILTER, auth.user)" type="checkbox" :id="`drinking_${d.value}`">
                                                <label class="checkbox__label" :for="`drinking_${d.value}`">
                                                    <div class="checkbox__icon">
                                                        <svg width="16" viewBox="0 0 24 24" xml:space="preserve"><path fill="#fff" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                                                    </div>
                                                    {{$t(`form_fields.drinking_options_${d.value}`)}}
                                                </label>
                                            </div>
                                        </template>
                                    </Accordion>
                                    <Accordion>
                                        <template v-slot:asideTitle>
                                            {{$t('form_fields.education_label')}}
                                            <div class="tags-aside">
                                                <div v-if="searchPayload.education.length">
                                                    <span>
                                                        {{$t(`form_fields.education_options_${searchOptions.education.find(e => searchPayload.education.includes(e.value))?.value}`)}}
                                                    </span>
                                                </div>
                                                <div v-if="searchPayload.education.length > 1"> {{searchPayload.education.filter(e => e !== searchPayload.education[0]).length > 0 ?  ('+' + searchPayload.education.filter(e => e !== searchPayload.education[0]).length) : ''}}</div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.29" height="6"><path data-name="Icon awesome-angle-down" d="M4.154 5.795.201 1.845a.694.694 0 0 1 0-.985l.66-.655a.694.694 0 0 1 .985 0l2.8 2.8 2.8-2.8a.694.694 0 0 1 .985 0l.656.656a.694.694 0 0 1 0 .985l-3.95 3.95a.691.691 0 0 1-.982 0Z"/></svg>
                                            </div>
                                        </template>
                                        <template v-slot:asideOptions>
                                            <div v-for="e in searchOptions.education" :key="e.value" class="checkbox">
                                                <input class="form-check-input" v-model="searchPayload.education" :value="e.value" type="checkbox" @change="pushDataLayer(SEARCH_EDUCATION_FILTER, auth.user)" :id="`education_${e.value}`">
                                                <label class="checkbox__label" :for="`education_${e.value}`">
                                                    <div class="checkbox__icon">
                                                        <svg width="16" viewBox="0 0 24 24" xml:space="preserve"><path fill="#fff" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                                                    </div>
                                                    {{$t(`form_fields.education_options_${e.value}`)}}
                                                </label>
                                            </div>
                                        </template>
                                    </Accordion>
                                    <Accordion>
                                        <template v-slot:asideTitle>
                                            {{$t('form_fields.ethnicity_label')}}
                                            <div class="tags-aside">
                                                <div v-if="searchPayload.ethnicity.length">
                                                    <span>
                                                        {{$t(`form_fields.ethnicity_options_${searchOptions.ethnicities.find(e => searchPayload.ethnicity.includes(e.value))?.value}`)}}
                                                    </span>
                                                </div>
                                                <div v-if="searchPayload.ethnicity.length > 1"> {{searchPayload.ethnicity.filter(e => e !== searchPayload.ethnicity[0]).length > 0 ?  ('+' + searchPayload.ethnicity.filter(e => e !== searchPayload.ethnicity[0]).length) : ''}}</div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.29" height="6"><path data-name="Icon awesome-angle-down" d="M4.154 5.795.201 1.845a.694.694 0 0 1 0-.985l.66-.655a.694.694 0 0 1 .985 0l2.8 2.8 2.8-2.8a.694.694 0 0 1 .985 0l.656.656a.694.694 0 0 1 0 .985l-3.95 3.95a.691.691 0 0 1-.982 0Z"/></svg>
                                            </div>
                                        </template>
                                        <template v-slot:asideOptions>
                                            <div v-for="e in searchOptions.ethnicities" :key="e.value" class="checkbox">
                                                <input class="form-check-input" v-model="searchPayload.ethnicity" :value="e.value" type="checkbox" @change="pushDataLayer(SEARCH_ETHNICITY_FILTER, auth.user)" :id="`ethnicity_${e.value}`">
                                                <label class="checkbox__label" :for="`ethnicity_${e.value}`">
                                                    <div class="checkbox__icon">
                                                        <svg width="16" viewBox="0 0 24 24" xml:space="preserve"><path fill="#fff" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                                                    </div>
                                                    {{$t(`form_fields.ethnicity_options_${e.value}`)}}
                                                </label>
                                            </div>
                                        </template>
                                    </Accordion>
                                    <Accordion>
                                        <template v-slot:asideTitle>
                                            {{$t('form_fields.hair_color_label')}}
                                            <div class="tags-aside">
                                                <div v-if="searchPayload.hair_color.length">
                                                    <span>
                                                        {{$t(`form_fields.hair_color_options_${searchOptions.hairColor.find(e => searchPayload.hair_color.includes(e.value))?.value}`)}}
                                                    </span>
                                                </div>
                                                <div v-if="searchPayload.hair_color.length > 1"> {{searchPayload.hair_color.filter(e => e !== searchPayload.hair_color[0]).length > 0 ?  ('+' + searchPayload.hair_color.filter(e => e !== searchPayload.hair_color[0]).length) : ''}}</div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.29" height="6"><path data-name="Icon awesome-angle-down" d="M4.154 5.795.201 1.845a.694.694 0 0 1 0-.985l.66-.655a.694.694 0 0 1 .985 0l2.8 2.8 2.8-2.8a.694.694 0 0 1 .985 0l.656.656a.694.694 0 0 1 0 .985l-3.95 3.95a.691.691 0 0 1-.982 0Z"/></svg>
                                            </div>
                                        </template>
                                        <template v-slot:asideOptions>
                                            <div v-for="h in searchOptions.hairColor" :key="h.value" class="checkbox">
                                                <input class="form-check-input" v-model="searchPayload.hair_color" :value="h.value" type="checkbox" @change="pushDataLayer(SEARCH_HAIR_COLOR_FILTER, auth.user)" :id="`hair_${h.value}`">
                                                <label class="checkbox__label" :for="`hair_${h.value}`">
                                                    <div class="checkbox__icon">
                                                        <svg width="16" viewBox="0 0 24 24" xml:space="preserve"><path fill="#fff" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                                                    </div>
                                                    {{$t(`form_fields.hair_color_options_${h.value}`)}}
                                                </label>
                                            </div>
                                        </template>
                                    </Accordion>
                                    <Accordion>
                                        <template v-slot:asideTitle>
                                            {{$t('form_fields.eye_color_label')}}
                                            <div class="tags-aside">
                                                <div v-if="searchPayload.eye_color.length">
                                                    <span>
                                                        {{$t(`form_fields.eye_color_options_${searchOptions.eyeColor.find(e => searchPayload.eye_color.includes(e.value))?.value}`)}}
                                                    </span>
                                                </div>
                                                <div v-if="searchPayload.eye_color.length > 1"> {{searchPayload.eye_color.filter(e => e !== searchPayload.eye_color[0]).length > 0 ?  ('+' + searchPayload.eye_color.filter(e => e !== searchPayload.eye_color[0]).length) : ''}}</div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.29" height="6"><path data-name="Icon awesome-angle-down" d="M4.154 5.795.201 1.845a.694.694 0 0 1 0-.985l.66-.655a.694.694 0 0 1 .985 0l2.8 2.8 2.8-2.8a.694.694 0 0 1 .985 0l.656.656a.694.694 0 0 1 0 .985l-3.95 3.95a.691.691 0 0 1-.982 0Z"/></svg>
                                            </div>
                                        </template>
                                        <template v-slot:asideOptions>
                                            <div v-for="h in searchOptions.eyeColor" :key="h.value" class="checkbox">
                                                <input class="form-check-input" v-model="searchPayload.eye_color" :value="h.value" type="checkbox" @change="pushDataLayer(SEARCH_EYE_COLOR_FILTER, auth.user)" :id="`eye_${h.value}`">
                                                <label class="checkbox__label" :for="`eye_${h.value}`">
                                                    <div class="checkbox__icon">
                                                        <svg width="16" viewBox="0 0 24 24" xml:space="preserve"><path fill="#fff" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                                                    </div>
                                                    {{$t(`form_fields.eye_color_options_${h.value}`)}}
                                                </label>
                                            </div>
                                        </template>
                                    </Accordion>
                                    <Accordion>
                                        <template v-slot:asideTitle>
                                            {{$t('form_fields.piercing_label')}}
                                            <div class="tags-aside">
                                                <div v-if="searchPayload.piercing.length">
                                                    <span>
                                                        {{$t(`form_fields.piercing_options_${searchOptions.piercings.find(e => searchPayload.piercing.includes(e.value))?.value}`)}}
                                                    </span>
                                                </div>
                                                <div v-if="searchPayload.piercing.length > 1">{{searchPayload.piercing.filter(e => e !== searchPayload.piercing[0]).length > 0 ?  ('+' + searchPayload.piercing.filter(e => e !== searchPayload.piercing[0]).length) : ''}}</div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.29" height="6"><path data-name="Icon awesome-angle-down" d="M4.154 5.795.201 1.845a.694.694 0 0 1 0-.985l.66-.655a.694.694 0 0 1 .985 0l2.8 2.8 2.8-2.8a.694.694 0 0 1 .985 0l.656.656a.694.694 0 0 1 0 .985l-3.95 3.95a.691.691 0 0 1-.982 0Z"/></svg>
                                            </div>
                                        </template>
                                        <template v-slot:asideOptions>
                                            <div v-for="p in searchOptions.piercings" :key="p.value" class="checkbox">
                                                <input class="form-check-input" v-model="searchPayload.piercing" :value="p.value" type="checkbox" @change="pushDataLayer(SEARCH_PIERCINGS_FILTER, auth.user)" :id="`piercing_${p.value}`">
                                                <label class="checkbox__label" :for="`piercing_${p.value}`">
                                                    <div class="checkbox__icon">
                                                        <svg width="16" viewBox="0 0 24 24" xml:space="preserve"><path fill="#fff" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                                                    </div>
                                                    {{$t(`form_fields.piercing_options_${p.value}`)}}
                                                </label>
                                            </div>
                                        </template>
                                    </Accordion>
                                    <Accordion>
                                        <template v-slot:asideTitle>
                                            {{$t('form_fields.smoking_label')}}
                                            <div class="tags-aside">
                                                <div v-if="searchPayload.smoking.length">
                                                    <span>
                                                        {{$t(`form_fields.smoking_options_${searchOptions.smoking.find(e => searchPayload.smoking.includes(e.value))?.value}`)}}
                                                    </span>
                                                </div>
                                                <div v-if="searchPayload.smoking.length > 1">{{searchPayload.smoking.filter(e => e !== searchPayload.smoking[0]).length > 0 ?  ('+' + searchPayload.smoking.filter(e => e !== searchPayload.smoking[0]).length) : ''}}</div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.29" height="6"><path data-name="Icon awesome-angle-down" d="M4.154 5.795.201 1.845a.694.694 0 0 1 0-.985l.66-.655a.694.694 0 0 1 .985 0l2.8 2.8 2.8-2.8a.694.694 0 0 1 .985 0l.656.656a.694.694 0 0 1 0 .985l-3.95 3.95a.691.691 0 0 1-.982 0Z"/></svg>
                                            </div>
                                        </template>
                                        <template v-slot:asideOptions>
                                            <div v-for="s in searchOptions.smoking" :key="s.value" class="checkbox">
                                                <input class="form-check-input" v-model="searchPayload.smoking" :value="s.value" type="checkbox" @change="pushDataLayer(SEARCH_SMOKING_FILTER, auth.user)" :id="`smoking_${s.value}`">
                                                <label class="checkbox__label" :for="`smoking_${s.value}`">
                                                    <div class="checkbox__icon">
                                                        <svg width="16" viewBox="0 0 24 24" xml:space="preserve"><path fill="#fff" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                                                    </div>
                                                    {{$t(`form_fields.smoking_options_${s.value}`)}}
                                                </label>
                                            </div>
                                        </template>
                                    </Accordion>
                                    <Accordion>
                                        <template v-slot:asideTitle>
                                            {{$t('form_fields.tattoo_label')}}
                                            <div class="tags-aside">
                                                <div v-if="searchPayload.tattoo.length">
                                                    <span>
                                                        {{$t(`form_fields.tattoo_options_${searchOptions.tattoos.find(e => searchPayload.tattoo.includes(e.value))?.value}`)}}
                                                    </span>
                                                </div>
                                                <div v-if="searchPayload.tattoo.length > 1">{{searchPayload.tattoo.filter(e => e !== searchPayload.tattoo[0]).length > 0 ?  ('+' + searchPayload.tattoo.filter(e => e !== searchPayload.tattoo[0]).length) : ''}}</div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.29" height="6"><path data-name="Icon awesome-angle-down" d="M4.154 5.795.201 1.845a.694.694 0 0 1 0-.985l.66-.655a.694.694 0 0 1 .985 0l2.8 2.8 2.8-2.8a.694.694 0 0 1 .985 0l.656.656a.694.694 0 0 1 0 .985l-3.95 3.95a.691.691 0 0 1-.982 0Z"/></svg>
                                            </div>
                                        </template>
                                        <template v-slot:asideOptions>
                                            <div v-for="t in searchOptions.tattoos" :key="t.value" class="checkbox">
                                                <input class="form-check-input" v-model="searchPayload.tattoo" :value="t.value" type="checkbox" @change="pushDataLayer(SEARCH_TATTOOS_FILTER, auth.user)" :id="`tattoo_${t.value}`">
                                                <label class="checkbox__label" :for="`tattoo_${t.value}`">
                                                    <div class="checkbox__icon">
                                                        <svg width="16" viewBox="0 0 24 24" xml:space="preserve"><path fill="#fff" d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/></svg>
                                                    </div>
                                                    {{$t(`form_fields.tattoo_options_${t.value}`)}}
                                                </label>
                                            </div>
                                        </template>
                                    </Accordion>
                                </div>
                            </div>

                            <div class="aside-footer">
                                <a class="button button--primary" @click="asideTrigger = false">{{$t('search.show_results')}}</a>
                            </div>
                        </div>
                    </aside>
                    <div class="content-grid-wrapper">
                        <div class="no-users" v-if="loaded && searchResults.length === 0">
                            <img src="@/assets/img/no-users.svg" alt="no users"/>
                            <h1>{{$t('search.seems')}}</h1>
                            <p>{{$t('search.sometimes')}}</p>
                        </div>
                        <div class="grid" v-if="searchResults.length">
                            <div v-for="u in searchResults.slice(0, 8)" :key="u.id" class="grid__item">
                                <Placeholder  :unlocked="unlocked.includes(u.id)" class="results-content__item" @chat="showChatModal = true; getConversation(u.id)" :user="u" />
                            </div>
                            <PWABanner @select="pwaBannerClickHandler" v-if="canPWABeInstalled()"/>
                            <PushBanner @select="pushBannerClickHandler" v-if="showPushNotificationBanner()"/>

                            <div v-for="u in searchResults.slice(8)" :key="u.id" class="grid__item">
                                <Placeholder  :unlocked="unlocked.includes(u.id)" class="results-content__item" @chat="showChatModal = true; getConversation(u.id)" :user="u" />
                            </div>
                        </div>
                        <Pagination :fake="fakePagination" :ignore="ignorePages" :info="pagination" @page-click="setPage" />
                    </div>
                </div>


                <Modal class="modal modal--chat" @close="showChatModal = false; this.conversation = null" :size="'medium'" v-if="showChatModal">
                    <template #header>
                    </template>
                    <template #body>
                        <ContentLoader v-if="loadingChat" />
                        <Messages v-else-if="conversation" :conversations="{data: [conversation], total: 1}" :display-sidebar="false" />
                    </template>
                </Modal>

            </article>
        </div>
    </main>
</template>

<script>
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import { oppositeGender, getSearchQueryString, locationPayload } from "@/service/user";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import {
    bodyTypes, interests, additional_interests,  drinking, education, ethnicities, hairColor,
    piercings, smoking, tattoos, searchResultsOrderBy, defaultHeight, defaultAge, defaultDistance, eyeColor
} from "@/service/forms";
import { search } from "@/api/users";
import { mapState, mapActions } from "vuex"
import Placeholder from "@/components/search/results/Placeholder";
import Accordion from "@/components/generic/Accordion";
import Pagination from "@/components/generic/Pagination";
import {getPaginationPayload, canPWABeInstalled, isOS} from "@/service/general";
import Modal from "@/components/generic/Modal";
import Messages from "@/components/messages/Messages";
import {conversation, getOrCreate} from "@/api/messages";
import ContentLoader from "@/components/ContentLoader";
import {TYPE_GENDER_MALE} from "@/types/user";
import Banner from "@/components/generic/Banner";
import SwitchButton from "@/components/generic/SwitchButton.vue";
import {pushDataLayer} from "@/service/gtm";
import {
    SEARCH_ORDER_BY_FILTER,
    SEARCH_DISTANCE_FILTER,
    SEARCH_BODY_TYPE_FILTER,
    SEARCH_LOOKING_FOR_FILTER,
    SEARCH_AGE_FILTER,
    SEARCH_DRINKING_FILTER,
    SEARCH_EDUCATION_FILTER,
    SEARCH_ETHNICITY_FILTER,
    SEARCH_HAIR_COLOR_FILTER,
    SEARCH_EYE_COLOR_FILTER,
    SEARCH_HEIGHT_FILTER,
    SEARCH_PIERCINGS_FILTER,
    SEARCH_SMOKING_FILTER,
    SEARCH_TATTOOS_FILTER,
    SEARCH_VERIFIED_ONLY_FILTER,
    SEARCH_HIDE_CONTACTED_FILTER,
    SEARCH_COUNTRY_STRICT_FILTER
} from "@/service/gtm/types";
import { SEARCH_PER_PAGE_RESULTS } from "@/utilities/search";
import PWABanner from "@/components/pwa/PWABanner.vue";
import PushBanner from "@/components/pwa/PushBanner.vue";
import {pushNotificationsDecided, pushNotificationSupported} from "@/service/push_notifications/push";
import {Android} from "@/types/device";
import {ucFirst} from "@/utilities/general";

export default {
    name: "Search",
    components: {PushBanner, SwitchButton, VueSlider, Placeholder, Accordion, Pagination, Modal, Messages, ContentLoader, Banner, PWABanner },
    mixins: [AuthenticatedMixin],
    data() {
        return {
            loaded: false,
            filterLoader: false,
            working: false,
            asideTrigger: false,
            conversation: null,
            showChatModal: false,
            loadingChat: false,
            theme: process.env.VUE_APP_THEME,
            searchOptions: {
                searchResultsOrderBy,
                drinking,
                interests: [...interests, ...additional_interests],
                bodyTypes: [],
                education,
                ethnicities,
                hairColor,
                eyeColor,
                piercings,
                smoking,
                tattoos
            },
            searchTimeout: null,
            searchPayload: {
                distance: defaultDistance,
                body_type: [],
                age: defaultAge,
                interested_in: [],
                drinking: [],
                education: [],
                ethnicity: [],
                hair_color: [],
                eye_color: [],
                height: defaultHeight,
                piercing: [],
                smoking: [],
                tattoo: [],
                orderBy: null,
                show_contacted: false,
                country_strict: false,
                verified: false,
            },
            page: 1,
            searchResults: [],
            loading: false,
            pagination: null,
            filter:"Recently active",
            selectOption: false,
            ignorePages: [],
            fakePagination: true,
            searchFilter: false,
            unwatch: null,
            order: null,
            perPage: SEARCH_PER_PAGE_RESULTS
        }
    },
    watch: {
        stateSearchLocation: {
            handler(value) {

                if(value) {

                    this.searchPayload = {
                        ...this.searchPayload,
                        latitude: value.latitude,
                        longitude: value.longitude,
                        city: value.city,
                        country: value.country,
                    }

                }

                if( locationPayload(this.auth.user))
                    this.requestSearch()
            },
            deep: true
        },
        '$route.query.page': {
            handler: async function(page) {
                await this.requestPage(page)
            }
        },
        liveFavorites(data) {
            this.searchResults = this.searchResults.map(user => {
                if(data.includes(user.id) && ! user.my_favorite) {
                    return {
                        ...user,
                        my_favorite: 1
                    }
                }
                return user
            })
        }
    },
    computed: {
        ...mapState({
            stateSearchLocation: state => state.search.location,
            stateSearchQuery: state => state.search.query,
            mobile: state => state.application.mobile,
            liveFavorites: state => state.search.myFavorite,
            stateSearchResults: state => state.search.data,
            stateScrollPosition: state => state.search.scroll_position,
            unlocked: state => state.access.available,
            historyLocations: state => state.search.historyLocations,
            installed: state => state.application.installed,
            pushEnabled: state => state.application.push_enabled
        }),

        firstSelectedInterest() {
            return this.searchOptions.interests.find(e => this.searchPayload.interested_in[0] === e.value)
        },

        searchCriteria() {
            let out = [];

            if (this.searchPayload?.distance && this.searchPayload.distance !== defaultDistance) {
                out.push({
                    title: this.searchPayload.distance + 'km',
                    callback: () => {
                        this.searchPayload.distance = defaultDistance
                        this.requestSearch()
                    }
                })
            }

            [
                'body_type', 'drinking',
                'education', 'ethnicity', 'hair_color', 'eye_color', 'piercing', 'smoking',
                'tattoo'
            ].forEach(o => {

                if (this.searchPayload[o]) {
                    this.searchPayload[o].map(e => {
                        out.push({
                            title: this.$i18n.t(`form_fields.${o}_options_${e}`),
                            callback: () => {
                                this.searchPayload[o] = this.searchPayload[o].filter(i => i !== e)
                                this.requestSearch()
                            }
                        })
                    })
                }

            });

            this.searchPayload?.interested_in.map(o => {
                let el = this.searchOptions.interests.find(i => i.value === o)
                out.push({
                    title: el.code === 'selling_pics' ? this.$i18n.t(`signup.new_registration.interests.selling_pics.${this.auth.user.gender === TYPE_GENDER_MALE ? 'sb' : 'sd'}`) : this.$i18n.t(`signup.new_registration.interests.${el.code}`),
                    callback: () => {
                        this.searchPayload.interested_in = this.searchPayload.interested_in.filter(i => i !== o)
                        this.requestSearch()
                    }
                })
            })


            if (JSON.stringify(this.searchPayload.age) !== JSON.stringify(defaultAge)) {
                out.push({
                    title: `${this.searchPayload.age[0]} - ${this.searchPayload.age[1]}y`,
                    callback: () => {
                        this.searchPayload.age = defaultAge
                        this.requestSearch()
                    }
                })
            }


            if (JSON.stringify([...this.searchPayload.height]) !== JSON.stringify([...defaultHeight])) {
                out.push({
                    title: `${this.searchPayload.height[0]} - ${this.searchPayload.height[1]}cm`,
                    callback: () => {
                        this.searchPayload.height = defaultHeight
                        this.requestSearch()
                    }
                })
            }

            if (this.searchPayload.show_contacted === true) {
                out.push({
                    title: this.$i18n.t('search.show_contacts'),
                    callback: () => {
                        this.searchPayload.show_contacted = false
                        this.requestSearch()
                    }
                })
            }

            if (this.searchPayload.country_strict === true) {
                out.push({
                    title: `${this.stateSearchLocation.country_long}`,
                    callback: () => {
                        this.searchPayload.country_strict = false
                        this.requestSearch()
                    }
                })
            }

            if (this.searchPayload.verified === true) {
                out.push({
                    title: this.$i18n.t('users.is_verified'),
                    callback: () => {
                        this.searchPayload.verified = false
                        this.requestSearch()
                    }
                })
            }

            return out
        }
    },
    methods: {
        ucFirst,
        pushBannerClickHandler() {
            this.add('push_notifications')
        },
        showPushNotificationBanner() {

            if (!pushNotificationSupported())
                return false

            if (this.pushEnabled)
                return false

            if (pushNotificationsDecided())
                return false

            if (isOS(Android) && !this.installed)
                return false

            return true
        },
        canPWABeInstalled,
        ...mapActions({
            add: 'profile/add'
        }),
        pwaBannerClickHandler() {
            this.add('install_pwa')
        },
        accordionChange(orderItem) {
            this.order = orderItem
        },

        pushDataLayer,
        ...mapActions({
            setStateSearchQuery: 'search/setQuery',
            setStateSearchResults: 'search/setData',
        }),
        async getConversation(user_id) {
            this.loadingChat = true
            const payload = await getOrCreate(user_id)
            const { data } = await conversation(payload.data.id)
            this.conversation = data
            this.loadingChat = false
        },
        async setPage(page) {
            await this.$router.push({name: 'search', query: {page: page}})
        },
        async requestPage(page) {

            if( ! this.stateSearchLocation)
                return

            let previous = this.page
            this.page = page
            this.working = true

            window.scrollTo({
                top:0,
                behavior: 'smooth'
            })

            clearTimeout(this.searchTimeout)

            this.searchTimeout = setTimeout(async () => {

                let queryString = getSearchQueryString({
                    ...this.searchPayload,
                    latitude: this.stateSearchLocation.latitude,
                    longitude: this.stateSearchLocation.longitude,
                    city: this.stateSearchLocation.city,
                    country: this.stateSearchLocation.country,
                    page: this.page,
                    perPage: this.perPage
                })

                const { data } = await search(queryString)

                this.loaded = true

                if(data.data.length) {

                    this.searchResults = []

                    await this.$nextTick(() => {
                        this.searchResults = data.data
                    })

                    this.pagination = getPaginationPayload(data)

                } else {
                    this.ignorePages.push(parseInt(page))
                    this.page = previous
                    await this.$router.replace({
                        ...this.$router.currentRoute,
                        query: {
                            page: previous
                        }
                    })
                }

                this.working = false

                await this.setStateSearchResults(data)

                await this.setStateSearchQuery({...this.searchPayload, page: parseInt(this.page), queryString})

            }, 10)

        },
        reset() {
            this.searchPayload = {
                ...this.searchPayload,
                distance: defaultDistance,
                body_type: [],
                age: defaultAge,
                interested_in: [],
                drinking: [],
                education: [],
                ethnicity: [],
                hair_color: [],
                eye_color: [],
                height: defaultHeight,
                piercing: [],
                smoking: [],
                tattoo: [],
                page: 1,
                show_contacted: false,
                country_strict: false,
                verified: false
            }

        },

        async requestSearch() {

            this.working = true

            await this.$router.replace({
                ...this.$router.currentRoute,
                query: {
                    page: undefined
                }
            })

            clearTimeout(this.searchTimeout)

            if( ! this.stateSearchLocation)
                return



            this.searchTimeout = setTimeout(async () => {

                let queryString = getSearchQueryString({
                    ...this.searchPayload,
                    latitude: this.stateSearchLocation.latitude,
                    longitude: this.stateSearchLocation.longitude,
                    city: this.stateSearchLocation.city,
                    country: this.stateSearchLocation.country,
                    page: this.page,
                    perPage: this.perPage
                })

                const { data } = await search(queryString)

                this.loaded = true

                this.working = false
                this.searchResults = []

                await this.$nextTick(() => {
                    this.searchResults = [...data.data]
                })

                if( ! data.data.length) {
                    return this.pagination = null
                } else {
                    this.ignorePages = []
                }

                this.pagination = getPaginationPayload(data)

                await this.setStateSearchResults({...data, page: this.pagination.current_page, queryString})

            }, 750)
        },
    },
    created() {

        this.defaultDistance = defaultDistance
        this.defaultAge = defaultAge
        this.defaultHeight = defaultHeight
        this.TYPE_GENDER_MALE = TYPE_GENDER_MALE

        this.searchOptions.bodyTypes = bodyTypes.filter(e => e.genders.includes(oppositeGender(this.auth.user.gender)))
        this.searchOptions.searchResultsOrderBy = this.searchOptions.searchResultsOrderBy.filter(e => e.genders.includes(this.auth.user.gender))

        if(this.stateSearchQuery) {
            return this.searchPayload = {
                ...this.stateSearchQuery
            }
        }

        this.searchPayload.orderBy          = this.searchOptions.searchResultsOrderBy[0].value
        this.SEARCH_ORDER_BY_FILTER         = SEARCH_ORDER_BY_FILTER
        this.SEARCH_DISTANCE_FILTER         = SEARCH_DISTANCE_FILTER
        this.SEARCH_BODY_TYPE_FILTER        = SEARCH_BODY_TYPE_FILTER
        this.SEARCH_LOOKING_FOR_FILTER      = SEARCH_LOOKING_FOR_FILTER
        this.SEARCH_AGE_FILTER              = SEARCH_AGE_FILTER
        this.SEARCH_DRINKING_FILTER         = SEARCH_DRINKING_FILTER
        this.SEARCH_EDUCATION_FILTER        = SEARCH_EDUCATION_FILTER
        this.SEARCH_ETHNICITY_FILTER        = SEARCH_ETHNICITY_FILTER
        this.SEARCH_HAIR_COLOR_FILTER       = SEARCH_HAIR_COLOR_FILTER
        this.SEARCH_EYE_COLOR_FILTER        = SEARCH_EYE_COLOR_FILTER
        this.SEARCH_HEIGHT_FILTER           = SEARCH_HEIGHT_FILTER
        this.SEARCH_PIERCINGS_FILTER        = SEARCH_PIERCINGS_FILTER
        this.SEARCH_SMOKING_FILTER          = SEARCH_SMOKING_FILTER
        this.SEARCH_TATTOOS_FILTER          = SEARCH_TATTOOS_FILTER
        this.SEARCH_VERIFIED_ONLY_FILTER    = SEARCH_VERIFIED_ONLY_FILTER
        this.SEARCH_HIDE_CONTACTED_FILTER   = SEARCH_HIDE_CONTACTED_FILTER
        this.SEARCH_COUNTRY_STRICT_FILTER   = SEARCH_COUNTRY_STRICT_FILTER

    },
    async mounted() {
        let page = parseInt(this.$route.query.page ?? 1)

        let queryString = getSearchQueryString({
            ...this.searchPayload,
            latitude: this.stateSearchLocation?.latitude,
            longitude: this.stateSearchLocation?.longitude,
            city: this.stateSearchLocation?.city,
            country: this.stateSearchLocation?.country,
            page: page,
            perPage: this.perPage
        })

        this.unwatch = this.$watch('searchPayload', async (value) => {
            await this.requestSearch()
            await this.setStateSearchQuery({...value, queryString: getSearchQueryString({
                    ...value,
                    latitude: this.stateSearchLocation?.latitude,
                    longitude: this.stateSearchLocation?.longitude,
                    city: this.stateSearchLocation?.city,
                    country: this.stateSearchLocation?.country,
                    page: page,
                    perPage: this.perPage
                })})
        }, {deep: true})

        if(this.stateSearchResults?.data?.length && (queryString === this.stateSearchQuery?.queryString)) {
            this.searchResults = this.stateSearchResults.data
            this.pagination = getPaginationPayload(this.stateSearchResults)
            this.loaded = true
            console.log('cached results...')
            if(this.stateScrollPosition && this.$route.previousRouteName === 'user') {
                this.$nextTick(() => window.scrollTo({top: this.stateScrollPosition}))
            }

        } else {
            await this.requestPage(this.$route.query.page || 1)
            this.searchFilter = true;
        }

    },
    beforeUnmount() {
        clearTimeout(this.searchTimeout)
        if(this.unwatch) {
            this.unwatch()
        }
    }
}
</script>

<style scoped lang="scss">

    .search-filters-main {
        min-width: 270px;
        width: 300px;
        max-width: calc(100% - 50px);
    }

    .tags-aside {
        display: flex;
        align-items: center;
        gap: 2px;

        >div {
            height: 26px;
            border-radius: 13px;
            padding: 0 10px;
            color: #fff;
            font-size: 16px;
            background-color: #000;
            display: flex;
            align-items: center;
            text-transform: capitalize;

            >span {
                overflow: hidden;
                display: inline-block;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width:110px;
                line-height: 1.3;
            }
        }

        svg {
            margin-left: 15px;
        }
    }

    .sub-header {
        @media(max-width: 991px) {
            display: flex;
            flex-direction: column-reverse;
        }
    }

    .loader {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    }

    .content-grid-wrapper {
        width: calc(100% - 320px);

        @media(max-width: 991px) {
            width: 100%;
        }
    }

    .grid {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        //grid-template-columns: repeat( auto-fill, minmax(200px, 1fr) );
        width: 100%;

        .grid__item {
            min-width: calc(25% - 15px);
            width: calc(25% - 15px);
        }

        @media(max-width: 1200px) {
            //grid-template-columns: repeat( auto-fill, minmax(350px, 1fr) );
            .grid__item {
                min-width:calc(50% - 10px);
                width: calc(50% - 10px);
            }
        }

        @media(max-width: 991px) {
            width: 100%;
        }


        @media(max-width: 768px) {
            gap: 6px;
            //grid-template-columns: repeat( auto-fill, minmax(200px, 1fr) );
            .grid__item {
                min-width:calc(50% - 3px);
                width: calc(50% - 3px);
            }
        }

        @media(max-width: 500px) {
            grid-template-columns: repeat( auto-fill, minmax(150px, 1fr) );
        }
    }

    .recently-list {
        li {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }

        a {
            color: #000000;
            display: block;

            &:hover,
            &.is-active {
                color: $color-type19!important;
            }
        }
    }

    .no-users {
        padding: 100px 0;
        text-align: center;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        // position: absolute;
        // left: 0;
        // top: 0;
        width: 100%;

        img {
            margin-bottom: 20px;
            width: 92px;
        }

        h1 {
            margin-bottom: 20px;
            font-weight: 700;
            font-size: 28px;
            line-height: 1.3;
            max-width: 300px;
        }

        p {
            padding: 0;
            line-height: 1.4;
            max-width: 300px;
        }
    }

    .search-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width:100%;

        img {
            display: none;
        }

        @media(max-width: 991px) {

            img {
                display: block;
            }
        }
    }

    .sub-nav-selected-filters {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        min-height: 47px;
        margin-top: 1px!important;


        .tags {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 3px;
            margin-right: 45px;

            @media(max-width: 991px) {
                justify-content: flex-start;
                width: 100%;
                overflow:auto;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }

        .tags__list-item {
            margin-right: 2px;
        }

        .tags__item,
        .tags__numb {
            display: flex;
            align-items: center;
            height: 22px;
            padding: 0 10px;
            background-color: #000;
            border-radius: 12px;
            font-size: 14px;
            color: #fff;

            span {
                line-height: 1;
                transform:translate(0,-1px);
                white-space: nowrap;
            }

            svg {
                margin-left: 5px;
            }
        }

        .filter-text {
            font-size: 18px;
        }

        p {
            display: flex;
            align-items: center;
            color: #000000;
            font-size: 18px;
            line-height: 1.3;
            font-weight: 400;
            margin-bottom: 0;

            .clear {
                padding: 0;
                margin-left: 10px;
                color: $color-type19;
            }
        }

        @media(max-width: 991px) {
            margin-bottom: 0;
            padding: 0;
            margin-top: 10px;

            &.has-filter {
                margin-bottom: 10px;
            }
        }
    }

    .filter-trigger {
        display: flex;
        align-items: center;
        background-color: #000;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
    }

    .clear {
        padding: 0 15px;
        font-size: 14px;
        color: $color-type18;
        white-space: nowrap;
        opacity: .3;

        &:hover {
            color: $color-type19;
        }

        &.is-active {
            opacity: 1;
        }
    }

    .results-content {
        width: 100%;
        position: relative;
        // z-index: -1;
        padding-bottom: 50px;

        // &.is-active {
        //     z-index: 9999;
        // }

        .row {
            >div {
                margin-bottom: 30px;
            }
        }

        @media(max-width: 991px) {
            width: 100%;
            padding-bottom: 90px;

            .row {
                margin: 0 -3px;
                >div {
                    padding: 0 3px;
                    margin-bottom: 6px!important;
                }
            }
        }
    }

    .aside {
        overflow: hidden;


        @media(min-width: 992px) {
            position: -webkit-sticky;
            position: sticky;
            top: 86px;
            margin-right: 20px;
            border-radius: 10px;
            background-color: $color-type1;
            padding: 20px 0 20px 20px;
            // display: flex;
            // flex-direction: column;
            min-width: 300px;
            height: calc(100dvh - 90px);

        }

        .aside-scroll {
            // overflow: auto;
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        .distance {
              border-top: 1px solid #efefef;
              border-bottom: 1px solid #efefef;
              padding-bottom: 10px;
              padding-top: 10px;
        }

        @media(max-width: 991px) {
            top: 0;
            right: 0;
            width: calc(100% - 40px);
            padding: 20px 0 15px 20px;
            transform: translate(100%, 0);
            transition: all .3s ease-in-out;
            visibility: visible;
            opacity: 1;
            height: 100%;
            display: flex;
            flex-direction: column;
            border-radius: 0;
            position: fixed;
            z-index: 999999;
            background-color: #fff;

            &:before {
                position: absolute;
                left: 0;
                bottom: 100%;
                width: 100%;
                height: 60px;
                transition: all .3s ease;
                background-color: #fff;
                content: "";
            }

            &.is-active {
                overflow: hidden;
                transform: translate(0, 0);
            }
        }
    }

    .aside-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        padding-right: 20px;

        h4 {
            margin-bottom:0;
            font-size: 16px;
            font-weight: 700;
        }

        .clear {
            color: $color-type10;
            font-size: 16px;
            padding: 0;
        }

        @media(max-width: 991px) {
            padding-right: 20px;
        }
    }

    .checkbox__label {
        font-size: 14px;
        height: 28px;
    }

    .aside-main {
        height: auto;
        min-height: calc(100% - 76px);
        // overflow: hidden;

        @media(max-width: 991px) {
            min-height: calc(100% - 76px);
            height: auto;
        }
    }

    .aside__search {
        margin-bottom: 20px;

        @media(max-width: 991px) {
            display: none;
        }
    }

    .aside__filters {
        overflow: auto;
        padding-right: 20px;
        // max-height: 70vh;
        flex: 1;

        @media(max-width: 991px) {
            padding-right: 20px;
            // max-height: initial;
        }
    }

    .aside-footer {
        padding: 15px 20px 0 0;
        align-items: center;
        justify-content: space-between;
        display: none;

        >a {
            width: 100%;
        }

        @media(max-width: 991px) {
            padding-right: 20px;
            display: flex;
        }
    }

    .aside-title {
        svg {
            margin-top: 1px;
            transform: rotate(0deg);
        }

        &.is-active {
            svg {
            transform: rotate(-180deg);

            }
        }
    }

    .order-by-filter {
        display: none;

        @media(max-width: 991px) {
            display: block;
        }
    }

    .sub-header--content {
        top: 0;
        position: sticky;
        background-color: #f5f5f5;
        background: linear-gradient(180deg, rgba(245,245,245,1) 90%, rgba(255,255,255,0) 100%);
        z-index: 1;

        @media(max-width: 991px) {
            margin-bottom: 0px;
            padding-top: 10px;
            padding-bottom: 10px;
            flex-direction: column !important;
            align-items: flex-start!important;
        }
    }

    .filter-text-trigger {
        display: none;

        @media(max-width: 991px) {
            position: absolute;
            right:5px;
            top: 15px;
            display: block;
        }
    }

</style>

<style lang="scss">

    .sub-nav-selected-filters {
        .aside-title {
            white-space: nowrap;
        }

        .tags-aside {
            flex-wrap: inherit;
        }
    }

    .sub-header--content {
        .search-filters-main {
            @media(max-width: 991px) {

                .search-header {
                    padding: 0!important;
                }

                &.active {
                    border-radius: 5px 5px 0 0;
                    margin-right: 0!important;
                    width: 100%;
                    max-width: 100%;
                }

                .autocomplete-dropdown {
                    border-top: 1px solid #bababa;
                    background-color: #fff;
                    padding: 20px;
                    margin-top: 0;
                    left: 0!important;
                    width: 100%!important;
                }

            }

            @media(min-width: 992px) {
                .autocomplete-dropdown {
                    padding: 20px;
                }
            }
        }
    }

    .overlay--filter {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9999;
    }
    .modal--chat {
        .chat-wrapper__header,
        .chat-wrapper__body,
        .chat-wrapper__footer {
            padding-left: 0;
            padding-right: 0;
        }

        .chat-wrapper__header {
            padding-top: 0;
        }

        .chat-wrapper__body {
            max-height: 50vh;
            padding-right: 10px;
        }

        .chat-wrapper__footer {
            padding-bottom: 0;
        }

        .dropdown-box {
            display: none;
        }

        .chat-wrapper {
            height: auto;
            max-height: 70%;
        }

        small {
            padding: 0;
            margin-top: 10px;
        }
    }

    .aside__search {
        .search-box > svg {
            top: 14px;
        }
    }


    .results-content__item {
        height: 100%;

        .user-panel {
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        .user-panel__header,
        .user-panel__body {
            flex: 1;
        }
    }

    .aside {
        .switch-wrap {
            @media (max-width: 768px) {
                padding-right: 20px!important;
            }
        }

        .tags-aside > div {
            font-size: 14px;
        }

        @media(max-width: 768px) {
            .switch-wrap:not(.pr) {
                padding-right: 0!important;
            }
        }
    }

    .order-by-dropdown {
        position: relative;
        z-index: 9999;
        min-width: 275px;

        .aside__article {
            border: none;
        }

        .aside-title {
            background-color: #ffffff;
            border-radius: 16px;
            padding: 10px 15px;
            position: relative;
            gap: 15px;
            transition: all .3s ease .2s;

            &.is-active {
                transition: all 0s ease 0s;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        .tags-aside > div {
            font-size: 14px;
        }

        .aside-options {
            position: absolute;
            left: 0;
            top: 100%;
            background-color: #fff;
            border-radius: 0 0 16px 16px;
            width: 100%;

            .aside-option__wrapper {
                padding: 15px;
            }

        }

        @media(max-width: 991px) {
            display: none;
        }
    }

    .sub-header {
        .banners__box {
            @media(max-width: 991px) {
                margin-bottom: 0;
            }
        }
    }
</style>