<template>

    <main>
        <section class="verify">
            <div class="o-container">

                <article>

                    <ContentLoader v-if="loading"/>
                    <div v-else>
                        <h1>{{$t('general.verify_email_address')}}</h1>
                        <div>
                            <svg v-if="success" class="verified-icon" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 32 32"><g data-name="Group 121"><path data-name="Polygon 2" d="M14.51 1.665a2 2 0 0 1 2.98 0l1.268 1.417a2 2 0 0 0 2.018.6l1.821-.5a2 2 0 0 1 2.5 1.606l.315 1.924a2 2 0 0 0 1.366 1.578l1.834.587a2 2 0 0 1 1.23 2.69l-.776 1.819a2 2 0 0 0 .294 2.055l1.251 1.523a2 2 0 0 1-.421 2.923l-1.614 1.1a2 2 0 0 0-.861 1.894l.236 1.956a2 2 0 0 1-1.943 2.24l-1.9.041a2 2 0 0 0-1.762 1.137l-.83 1.737a2 2 0 0 1-2.858.838l-1.6-.994a2 2 0 0 0-2.106 0l-1.6.994a2 2 0 0 1-2.858-.838l-.83-1.737a2 2 0 0 0-1.762-1.137l-1.9-.041a2 2 0 0 1-1.943-2.24l.236-1.956a2 2 0 0 0-.861-1.894l-1.614-1.1a2 2 0 0 1-.421-2.923l1.242-1.524a2 2 0 0 0 .294-2.055l-.776-1.819a2 2 0 0 1 1.23-2.69l1.833-.586a2 2 0 0 0 1.364-1.582L6.9 4.784a2 2 0 0 1 2.5-1.606l1.821.5a2 2 0 0 0 2.018-.6Z" fill="#2c7ddb"/><path data-name="Icon metro-checkmark" d="m21.668 9.461-8.165 8.163-3.81-3.81-2.719 2.722 6.529 6.531 10.886-10.885Z" fill="#fff"/></g></svg>
                            <img v-else width="100" :src="require(`@/assets/img/not-good.svg`)">
                            <p>{{$t(success ? 'verify.success' : 'general.not_verified')}}</p>
                            <button class="button button--primary w-100" @click="buttonClickHandler" v-if="success">{{$t('verify.discover')}}</button>
                        </div>
                    </div>
                </article>
            </div>
        </section>
    </main>
</template>

<script>
import { mapActions } from "vuex"
import { verifyEmail } from "@/api/auth";
import { bootUser } from "@/service";
import ContentLoader from "@/components/ContentLoader";
import { pushDataLayer } from "@/service/gtm";
import { GTM_EVENT_EMAIL_VERIFIED_PAGE, GTM_EVENT_EMAIL_VERIFIED_DISCOVER_CLICK } from "@/service/gtm/types";
import { GODFATHER_FLOW } from "@/types/flows";

export default {
    name: "VerifyEmailPage",
    components: {ContentLoader},
    data() {
        return {
            loading: true,
            success: false,
            user: null
        }
    },
    methods: {
        ...mapActions({
            setUser: 'authenticate/setUser'
        }),
       async buttonClickHandler() {
           pushDataLayer(GTM_EVENT_EMAIL_VERIFIED_DISCOVER_CLICK, this.$store.state.authenticate.auth.user)
           if(this.user.flow && this.user.flow.split(':').includes(GODFATHER_FLOW)) {
               return await this.$router.push({name: 'credits'})
           }
           await this.$router.push({name: 'search'})
        }
    },

    watch: {
        success: function(value) {
            if(value) {
                pushDataLayer(GTM_EVENT_EMAIL_VERIFIED_PAGE, this.$store.state.authenticate.auth.user)
            }
        }
    },

    async mounted() {

        const {user} = await bootUser()

        this.user = user

        if(this.user) {

            if( ! this.user.email_verified_at) {

                if(this.$route.query.verifyLink) {

                    try {

                        const { data } = await verifyEmail(this.$route.query.verifyLink)
                        await this.setUser(data)
                        this.loading = false
                        this.success = !! data.email_verified_at
                        return

                    } catch {

                        this.success = false

                    } finally {

                        await this.$router.replace({
                            ...this.$router.currentRoute,
                            query: {
                                verifyLink: undefined
                            }
                        })

                    }

                    this.loading = false
                    return

                }

                return this.success = false

            } else {

                this.loading = false
                this.success = true

            }

        } else {

            if( ! this.$route.query.verifyLink) {
                return this.$router.push({name: 'login'})
            }


            try {

                const { data } = await verifyEmail(this.$route.query.verifyLink)
                this.setUser(data)
                this.success = !! data.email_verified_at

            } catch {

                this.success = false

            }

            this.loading = false


        }

    }
}
</script>

<style scoped lang="scss">
    main {
        min-height: calc(100vh - 200px);
        height: auto;

        @media(max-width: 991px) {
            background-color: #fff;
            min-height: calc(100vh - 230px);
        }
    }
    .verify {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 90px 0;

        article {
            padding: 60px 90px 80px;
            max-width: 520px;
            margin: 0 auto;
            background-color: #fff;
            border-radius: 10px;
            text-align: center;
        }

        img,svg {
            margin-bottom: 30px;
        }

        @media(max-width: 500px) {
            padding: 30px 0;

            .button--big {
                height: 40px;
                border-radius: 20px;
            }

            img,svg {
                width: 70px;
                height: auto;
            }

            article {
                padding: 10px 20px;
                background-color: #fff;
                border-radius: 0;
            }
        }
    }
</style>